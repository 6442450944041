import React, { ReactElement } from "react";
import { Text, Box, Button, Center, SimpleGrid } from "@chakra-ui/react";
import { navigate } from "gatsby";
import styled, { keyframes } from "styled-components";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { bounce } from "react-animations";
import { StaticImage } from "gatsby-plugin-image";
import { Caption, Standard, SubtitleBold } from "../styled/Text";
import { Break } from "../styled/Break";
import { CategoryCard } from "./showcase/CatetgoryCard";
import { ListText } from "../styled/List";
import WizardIcon from "../../assets/wizard.svg";
import KnightIcon from "../../assets/knight.svg";
import KingIcon from "../../assets/king.svg";
import TreasureIcon from "../../assets/treasure.svg";
import KnightWoman from "../../assets/magician3.svg";
import Friends from "../../assets/friends.svg";

const bounceAnimation = keyframes`${bounce}`;

const BouncyIcon = styled(ChevronDownIcon)`
  animation: 3s ${bounceAnimation} infinite;
`;

export function DesktopIntroduction(): ReactElement {
  return (
    <Box>
      <StaticImage src="../../images/IntroductionDesktop.jpg" alt="heroine" placeholder="blurred" />
      <Text position="absolute" top="15vh" left="40vw" fontWeight={800} fontSize="4rem" color="black" fontFamily="linotte">
        Lightning-Fast Adventure
      </Text>
      {/* <Text position="absolute" top="25vh" left="43vw" fontWeight={600} fontSize="2rem" color="black" fontFamily="linotte">
        Card TTRPG with Powerful Homebrew Tools
      </Text> */}
      <Box position="absolute" top="48vh" left="43vw" spacing={16}>
        <Button
          bg="black"
          height="6vh"
          onClick={() => navigate("#player")}
          pr="2vw"
          width="13vw"
          _hover={{ background: "black" }}
          _active={{ background: "black" }}
          leftIcon={
            <Box zIndex={2}>
              <KnightIcon />
            </Box>
          }
        >
          <Text fontSize="1.5rem" fontWeight={600}>
            Player Path
          </Text>
        </Button>
      </Box>
      <Box position="absolute" top="48vh" left="63vw" spacing={16}>
        <Button
          bg="black"
          height="6vh"
          width="13vw"
          onClick={() => navigate("#game_master")}
          _hover={{ background: "black" }}
          _active={{ background: "black" }}
          leftIcon={<WizardIcon />}
        >
          <Text fontSize="1.5rem" fontWeight={600}>
            Game Master
          </Text>
        </Button>
      </Box>
      <ExpandedIntroduction isDesktop={true} />
    </Box>
  );
}

export function MobileIntroduction(): ReactElement {
  return (
    <Box>
      <StaticImage src="../../images/IntroductionMobile(7).jpg" alt="heroine" placeholder="blurred" />
      <Button
        bg="black"
        color="white"
        position="absolute"
        top="35vh"
        width="50vw"
        right="10vw"
        size="lg"
        _hover={{ background: "black" }}
        _active={{ background: "black" }}
        onClick={() => navigate("#player")}
        leftIcon={<KnightIcon />}
      >
        Player Path
      </Button>
      <Button
        bg="black"
        color="white"
        position="absolute"
        top="50vh"
        right="10vw"
        width="50vw"
        size="lg"
        _hover={{ background: "black" }}
        _active={{ background: "black" }}
        onClick={() => navigate("#game_master")}
        leftIcon={<WizardIcon />}
      >
        Game Master
      </Button>
      <ExpandedIntroduction isDesktop={false} />
    </Box>
  );
}

export function ExpandedIntroduction({ isDesktop }: { isDesktop: boolean }): ReactElement {
  const expandedImages = isDesktop ? desktopImages : mobileImages;

  return (
    <>
      <Break height="5vh" />
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} columnGap={16} ml="5vw" mr="5vw">
        <Box id="customer">
          <SubtitleBold text="Community" />
          <Center>
            <Friends />
          </Center>
          <Standard text={benefit1} />
        </Box>
        <Box>
          <SubtitleBold text="Fantasy" />
          <Center>
            <TreasureIcon />
          </Center>
          <Standard text={benefit2} />
        </Box>
        <Box>
          <SubtitleBold text="Eazy" />
          <Center>
            <KingIcon />
          </Center>
          <Standard text={benefit3} />
        </Box>
      </SimpleGrid>
      <Break height="5vh" />
      <SubtitleBold text="Features" />
      <SimpleGrid columns={{ sm: 1, lg: 2 }} ml="2vw" mr="2vw">
        <Box>{expandedImages.product}</Box>
        <ListText texts={featureList} />
      </SimpleGrid>
      <div id="player">
        <Break height="7vh" />
        <SubtitleBold text="Path of the Player" />
      </div>
      <Break height="2vh" />
      <SimpleGrid columns={{ sm: 1, lg: 3 }} gap={16} ml="2vw" mr="2vw">
        <CategoryCard category={{ name: "Character Sheet", url: "/sheet" }} staticImage={expandedImages.characterSheet} />
        <CategoryCard category={{ name: "Spellbook", url: "/spellbook" }} staticImage={expandedImages.spellbook} />
        <CategoryCard category={{ name: "Full Guide", url: "/play" }} staticImage={expandedImages.fullGuide} />
      </SimpleGrid>
      <Break height="7vh" />
      <div id="game_master">
        <SubtitleBold text="Way of the Game Master" />
      </div>
      <Break height="2vh" />
      <SimpleGrid columns={{ sm: 1, lg: 3 }} gap={16} ml="2vw" mr="2vw">
        <CategoryCard category={{ name: "Prepare your First Game", url: "/master" }} staticImage={expandedImages.firstGame} />
        <CategoryCard category={{ name: "Find Great Content", url: "/explore/monster" }} staticImage={expandedImages.greatContent} />
        <CategoryCard category={{ name: "Build Custom Worlds", url: "/create/monster" }} staticImage={expandedImages.customWorlds} />
      </SimpleGrid>
      <Break height="5vh" />
    </>
  );
}

const desktopImages = {
  characterSheet: <StaticImage src="../../images/CardCharacterSheet.jpg" alt="" />,
  spellbook: <StaticImage src="../../images/CardSpellbook.jpg" alt="" />,
  fullGuide: <StaticImage src="../../images/CardFullGuide.jpg" alt="" />,
  firstGame: <StaticImage src="../../images/CardFirstGame.jpg" alt="" />,
  greatContent: <StaticImage src="../../images/CardGreatContent.jpg" alt="" />,
  customWorlds: <StaticImage src="../../images/CardCustomWorlds.jpg" alt="" />,
  product: <StaticImage src="../../images/ProductImage.jpg" alt="" />,
};

const mobileImages = {
  characterSheet: <StaticImage src="../../images/CardCharacterSheetMobile.jpg" alt="" />,
  spellbook: <StaticImage src="../../images/CardSpellbookMobile.jpg" alt="" />,
  fullGuide: <StaticImage src="../../images/CardFullGuideMobile.jpg" alt="" />,
  firstGame: <StaticImage src="../../images/CardFirstGameMobile.jpg" alt="" />,
  greatContent: <StaticImage src="../../images/CardGreatContentMobile.jpg" alt="" />,
  customWorlds: <StaticImage src="../../images/CardCustomWorldsMobile.jpg" alt="" />,
  product: <StaticImage src="../../images/Product Image Mobile(5).jpg" alt="" />,
};

const benefit1 =
  "Share your love for table top RPGs with a game designed for outsiders! Your friends will be shocked by how quickly they get hooked.";
const benefit2 = "Build lasting memories and leave them wanting more. Player characters are deeply personal. Combat is tense and epic.";
const benefit3 = "Spend more time with your friends. Everything is streamlined for fantasy fun!";

const featureList = [
  "5 minutes for a character, even if you never played TTRPGs!",
  "Tense and fast paced combat system.",
  "Impactful choices at each level.",
  "Build custom monsters in minutes, NPCs in seconds.",
  "Massive library of homebrew monsters.",
  "Free character sheets, ruleset, and adventures.",
  "60 class, ancestry, and prestige cards for thousands of combinations!",
];
