import React, { ReactElement } from "react";

export function Sword(): ReactElement {
  return (
    <svg id="Layer_3" enableBackground="new 0 0 64 64" height="45" viewBox="0 0 64 64" width="45" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <path d="m29 21 1-14h4l1 14z" fill="#8a8275" />
        </g>
        <g>
          <path d="m26.182 25-2.546 28h16.84l-2.666-28z" fill="#d5cec2" />
        </g>
        <g>
          <path d="m24.748 40.777c5.535-1.067 9.717-5.931 9.717-11.777 0-1.405-.254-2.747-.697-4h-7.586z" fill="#e7e4dd" />
        </g>
        <g>
          <circle cx="32" cy="28" fill="#4d5d7a" r="3" />
        </g>
        <g>
          <path d="m43 26 6 1v-6l-6 1z" fill="#f5ba5b" />
        </g>
        <g>
          <path d="m21 26-6 1v-6l6 1z" fill="#f5ba5b" />
        </g>
        <g>
          <path d="m43 27v-6h-22v6h5l3-2h6l3 2z" fill="#f19846" />
        </g>
        <g>
          <circle cx="32" cy="5" fill="#243242" r="3" />
        </g>
        <g>
          <path d="m8 14c0-3.314 2.686-6 6-6-3.314 0-6-2.686-6-6 0 3.314-2.686 6-6 6 3.314 0 6 2.686 6 6z" fill="#f8cf6a" />
        </g>
        <g>
          <path d="m56 22c0-3.314 2.686-6 6-6-3.314 0-6-2.686-6-6 0 3.314-2.686 6-6 6 3.314 0 6 2.686 6 6z" fill="#f8cf6a" />
        </g>
        <g>
          <path d="m43 28h-5c-.197 0-.391-.059-.555-.168l-2.748-1.832h-5.395l-2.748 1.832c-.163.109-.357.168-.554.168h-5c-.552 0-1-.448-1-1v-6c0-.552.448-1 1-1h22c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1zm-4.697-2h3.697v-4h-20v4h3.697l2.748-1.832c.164-.109.358-.168.555-.168h6c.197 0 .391.059.555.168z" />
        </g>
        <g>
          <path d="m38.238 26.941h2v26.118h-2z" transform="matrix(.996 -.095 .095 .996 -3.616 3.9)" />
        </g>
        <g>
          <path d="m13.955 37h22.091v2h-22.091z" transform="matrix(.091 -.996 .996 .091 -15.113 59.446)" />
        </g>
        <g>
          <path d="m32 9c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
        </g>
        <g>
          <path d="m22.482 13h14.036v2h-14.036z" transform="matrix(.071 -.998 .998 .071 13.433 42.427)" />
        </g>
        <g>
          <path d="m33.5 6.982h2v14.036h-2z" transform="matrix(.998 -.071 .071 .998 -.91 2.494)" />
        </g>
        <g>
          <path d="m32 32c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
        </g>
        <g>
          <path d="m49 28c-.055 0-.11-.004-.165-.014l-6-1 .329-1.973 4.836.806v-3.639l-4.835.806-.329-1.973 6-1c.29-.049.586.034.811.223.224.191.353.47.353.764v6c0 .294-.129.573-.354.763-.182.154-.411.237-.646.237z" />
        </g>
        <g>
          <path d="m15 28c-.235 0-.464-.083-.646-.237-.225-.19-.354-.469-.354-.763v-6c0-.294.129-.573.354-.763.225-.189.519-.273.811-.223l6 1-.329 1.973-4.836-.806v3.639l4.835-.806.329 1.973-6 1c-.054.009-.109.013-.164.013z" />
        </g>
        <g>
          <path d="m33.5 34.972h2v9.055h-2z" transform="matrix(.994 -.11 .11 .994 -4.151 4.052)" />
        </g>
        <g>
          <path d="m8 15c-.552 0-1-.448-1-1 0-2.757-2.243-5-5-5-.552 0-1-.448-1-1s.448-1 1-1c2.757 0 5-2.243 5-5 0-.552.448-1 1-1s1 .448 1 1c0 2.757 2.243 5 5 5 .552 0 1 .448 1 1s-.448 1-1 1c-2.757 0-5 2.243-5 5 0 .552-.448 1-1 1zm-2.397-7c.98.591 1.806 1.417 2.397 2.397.591-.98 1.417-1.806 2.397-2.397-.98-.591-1.806-1.417-2.397-2.397-.591.98-1.417 1.806-2.397 2.397z" />
        </g>
        <g>
          <path d="m56 23c-.552 0-1-.448-1-1 0-2.757-2.243-5-5-5-.552 0-1-.448-1-1s.448-1 1-1c2.757 0 5-2.243 5-5 0-.552.448-1 1-1s1 .448 1 1c0 2.757 2.243 5 5 5 .552 0 1 .448 1 1s-.448 1-1 1c-2.757 0-5 2.243-5 5 0 .552-.448 1-1 1zm-2.397-7c.981.591 1.806 1.417 2.397 2.397.591-.981 1.417-1.806 2.397-2.397-.981-.591-1.806-1.417-2.397-2.397-.591.98-1.417 1.806-2.397 2.397z" />
        </g>
        <g>
          <path d="m38 53h9l5-6 10 8v7h-60l10-10 8-1 12-6 3 6 8.116 5.558-.116 5.442" fill="#4e4a40" />
        </g>
        <g>
          <path d="m19.631 62c4.94-3.323 8.267-8.833 8.646-15.139l-8.277 4.139-8 1-10 10z" fill="#625d51" />
        </g>
        <g>
          <path d="m62 63h-60c-.404 0-.769-.243-.924-.617s-.069-.804.217-1.09l10-10c.157-.157.362-.258.583-.285l7.83-.979 11.847-5.924c.496-.247 1.095-.047 1.342.447l2.884 5.769 2.451 1.679h8.302l4.7-5.641c.348-.416.968-.48 1.393-.141l10 8c.237.191.375.478.375.782v7c0 .553-.448 1-1 1zm-17.979-2h16.979v-5.52l-8.859-7.087-4.373 5.247c-.19.228-.471.36-.768.36h-5.849l2.53 1.732c.278.19.442.509.435.847zm-39.607 0h37.607l.083-3.923-7.669-5.252c-.14-.096-.253-.227-.33-.378l-2.553-5.105-11.105 5.553c-.102.051-.211.084-.323.098l-7.66.958z" />
        </g>
      </g>
    </svg>
  );
}

export function SwordMobile(): ReactElement {
  return (
    <svg id="Layer_3" enableBackground="new 0 0 64 64" height="45" viewBox="0 0 64 64" width="45" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <path d="m29 21 1-14h4l1 14z" fill="#8a8275" />
        </g>
        <g>
          <path d="m26.182 25-2.546 28h16.84l-2.666-28z" fill="#d5cec2" />
        </g>
        <g>
          <path d="m24.748 40.777c5.535-1.067 9.717-5.931 9.717-11.777 0-1.405-.254-2.747-.697-4h-7.586z" fill="#e7e4dd" />
        </g>
        <g>
          <circle cx="32" cy="28" fill="#4d5d7a" r="3" />
        </g>
        <g>
          <path d="m43 26 6 1v-6l-6 1z" fill="#f5ba5b" />
        </g>
        <g>
          <path d="m21 26-6 1v-6l6 1z" fill="#f5ba5b" />
        </g>
        <g>
          <path d="m43 27v-6h-22v6h5l3-2h6l3 2z" fill="#f19846" />
        </g>
        <g>
          <circle cx="32" cy="5" fill="#243242" r="3" />
        </g>
        <g>
          <path d="m8 14c0-3.314 2.686-6 6-6-3.314 0-6-2.686-6-6 0 3.314-2.686 6-6 6 3.314 0 6 2.686 6 6z" fill="#f8cf6a" />
        </g>
        <g>
          <path d="m56 22c0-3.314 2.686-6 6-6-3.314 0-6-2.686-6-6 0 3.314-2.686 6-6 6 3.314 0 6 2.686 6 6z" fill="#f8cf6a" />
        </g>
        <g>
          <path d="m43 28h-5c-.197 0-.391-.059-.555-.168l-2.748-1.832h-5.395l-2.748 1.832c-.163.109-.357.168-.554.168h-5c-.552 0-1-.448-1-1v-6c0-.552.448-1 1-1h22c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1zm-4.697-2h3.697v-4h-20v4h3.697l2.748-1.832c.164-.109.358-.168.555-.168h6c.197 0 .391.059.555.168z" />
        </g>
        <g>
          <path d="m38.238 26.941h2v26.118h-2z" transform="matrix(.996 -.095 .095 .996 -3.616 3.9)" />
        </g>
        <g>
          <path d="m13.955 37h22.091v2h-22.091z" transform="matrix(.091 -.996 .996 .091 -15.113 59.446)" />
        </g>
        <g>
          <path d="m32 9c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
        </g>
        <g>
          <path d="m22.482 13h14.036v2h-14.036z" transform="matrix(.071 -.998 .998 .071 13.433 42.427)" />
        </g>
        <g>
          <path d="m33.5 6.982h2v14.036h-2z" transform="matrix(.998 -.071 .071 .998 -.91 2.494)" />
        </g>
        <g>
          <path d="m32 32c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
        </g>
        <g>
          <path d="m49 28c-.055 0-.11-.004-.165-.014l-6-1 .329-1.973 4.836.806v-3.639l-4.835.806-.329-1.973 6-1c.29-.049.586.034.811.223.224.191.353.47.353.764v6c0 .294-.129.573-.354.763-.182.154-.411.237-.646.237z" />
        </g>
        <g>
          <path d="m15 28c-.235 0-.464-.083-.646-.237-.225-.19-.354-.469-.354-.763v-6c0-.294.129-.573.354-.763.225-.189.519-.273.811-.223l6 1-.329 1.973-4.836-.806v3.639l4.835-.806.329 1.973-6 1c-.054.009-.109.013-.164.013z" />
        </g>
        <g>
          <path d="m33.5 34.972h2v9.055h-2z" transform="matrix(.994 -.11 .11 .994 -4.151 4.052)" />
        </g>
        <g>
          <path d="m8 15c-.552 0-1-.448-1-1 0-2.757-2.243-5-5-5-.552 0-1-.448-1-1s.448-1 1-1c2.757 0 5-2.243 5-5 0-.552.448-1 1-1s1 .448 1 1c0 2.757 2.243 5 5 5 .552 0 1 .448 1 1s-.448 1-1 1c-2.757 0-5 2.243-5 5 0 .552-.448 1-1 1zm-2.397-7c.98.591 1.806 1.417 2.397 2.397.591-.98 1.417-1.806 2.397-2.397-.98-.591-1.806-1.417-2.397-2.397-.591.98-1.417 1.806-2.397 2.397z" />
        </g>
        <g>
          <path d="m56 23c-.552 0-1-.448-1-1 0-2.757-2.243-5-5-5-.552 0-1-.448-1-1s.448-1 1-1c2.757 0 5-2.243 5-5 0-.552.448-1 1-1s1 .448 1 1c0 2.757 2.243 5 5 5 .552 0 1 .448 1 1s-.448 1-1 1c-2.757 0-5 2.243-5 5 0 .552-.448 1-1 1zm-2.397-7c.981.591 1.806 1.417 2.397 2.397.591-.981 1.417-1.806 2.397-2.397-.981-.591-1.806-1.417-2.397-2.397-.591.98-1.417 1.806-2.397 2.397z" />
        </g>
        <g>
          <path d="m38 53h9l5-6 10 8v7h-60l10-10 8-1 12-6 3 6 8.116 5.558-.116 5.442" fill="#4e4a40" />
        </g>
        <g>
          <path d="m19.631 62c4.94-3.323 8.267-8.833 8.646-15.139l-8.277 4.139-8 1-10 10z" fill="#625d51" />
        </g>
        <g>
          <path d="m62 63h-60c-.404 0-.769-.243-.924-.617s-.069-.804.217-1.09l10-10c.157-.157.362-.258.583-.285l7.83-.979 11.847-5.924c.496-.247 1.095-.047 1.342.447l2.884 5.769 2.451 1.679h8.302l4.7-5.641c.348-.416.968-.48 1.393-.141l10 8c.237.191.375.478.375.782v7c0 .553-.448 1-1 1zm-17.979-2h16.979v-5.52l-8.859-7.087-4.373 5.247c-.19.228-.471.36-.768.36h-5.849l2.53 1.732c.278.19.442.509.435.847zm-39.607 0h37.607l.083-3.923-7.669-5.252c-.14-.096-.253-.227-.33-.378l-2.553-5.105-11.105 5.553c-.102.051-.211.084-.323.098l-7.66.958z" />
        </g>
      </g>
    </svg>
  );
}
