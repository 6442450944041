import { Box, Text, HStack, List, ListIcon, ListItem } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { Standard } from "./Text";
import { Sword } from "../../icons/Sword";
import { Break } from "./Break";
import { isDesktopHook } from "../../Hooks";

export function ListText({ texts }: Props): ReactElement {
  const isDesktop = isDesktopHook();

  const textElements = texts.map((t, idx) => {
    return (
      <ListItem key={`Text Item #${idx} ${t}`} mb="2vh">
        {isDesktop ? (
          <HStack spacing={4}>
            <ListIcon as={Sword} color="green.500" />
            <Box>
              <Break height=".6vh" />
              <Standard text={t} />
            </Box>
          </HStack>
        ) : (
          <>
            <Standard text={t} position="absolute" left="15vw" />
            <Sword />
          </>
        )}
      </ListItem>
    );
  });

  return <List spacing={3}>{textElements}</List>;
}

interface Props {
  texts: string[];
}
